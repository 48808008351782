.main-header{
  width: 100%;
  position: fixed;
  #{$left}: 0;
  top: 0;
  z-index: 100;
  transition: .2s;
  background: linear-gradient(to left, $color-orange 0, $color-orange 50%, $color-black 50%, $color-black 100%);

  @include respond-to('medium'){

  }
}

.main-header_has-shadow{
  box-shadow: $header-shadow-x
              $header-shadow-y
              $header-shadow-size
              $header-shadow-spread
              $header-shadow-color;
} //main-header_has-shadow

.header-bg{
  width: 100%;
  height: 100px;
  background: linear-gradient(to left, $color-orange 280px, $color-black 280px, $color-black 100%);
  position: absolute;
  //top: 0;
  //#{$left}: 0;
}

.header-desktop{
  height: 100px;
  position: relative;
  top: 0;
  transition: .3s;
  width: 100%;
  //box-shadow: 0 0 20px rgba(0,0,0,.15);
  //background-color: #fff;

  @include respond-to('medium'){
    display: none;
  }
} //header-desktop

.header-wrapper{
  max-width: $max-width;
  padding: $padding-base;
} //header-wrapper

.header-logo{
  padding: 10px;
  display: block;
  max-height: 100%;
  height: auto;
  transition: .3s;

  &__image{
    position: absolute;
    #{$right}: 0;
    top: 0;
  }
} //header-logo

.header-logo-small{
  position: absolute;
  bottom: -5px;
  #{$right}: calc((100% - 1400px) / 2);
  transition: .3s;
  opacity: 0;
}

.header-main{
  padding: 0 15px 0 0;

  @include respond-to('huge'){
    padding-#{$right}: 10px;
  } //---huge


  @at-root #{&}__phone{
    display: inline;
    position: absolute;
    #{$left}: 0;
    top: 33px;

    @include respond-to('huge'){
      padding-#{$left}: 15px;
    }
    @include respond-to('large'){
      text-align: $left;
    } //---huge

    span{
      font-size: 18px;
      font-weight: 700;
      color: $color-orange;
      letter-spacing: .1em;
      display: block;
      text-align: $left;

      &:first-of-type{
        //padding-#{$left}: 45px;

        @include respond-to('huge'){
          //padding-#{$left}: 20px;
        }
        @include respond-to('large'){
          //padding-#{$left}: 0px;
        } //---huge
      } //1st
      @include respond-to('large'){
        font-size: 16px;
        display: block;
      } //---huge
    } //span
  } //header-main__phone
} //header-main

.header-main__menu{
  margin-right: 300px;
  margin-top: 10px;
  display: inline-block;
}
.header-main__menu-item{
  display: inline-block;
	line-height: 18px;
	height: 100%;
  font-size: 18px;
	padding: 8px $header-menu-spaces/2;
  transition: .2s;
  position: relative;
  letter-spacing: -.02em;

  @include respond-to('huge'){
    padding-left: $header-menu-spaces/4;
    padding-right: $header-menu-spaces/4;
  } //---huge
  @include respond-to('large'){
    font-size: 16px;
    padding-left: $header-menu-spaces/6;
    padding-right: $header-menu-spaces/6;
  }

	a{
		color: $color-white;
		font-weight: 400;
	}

	&:hover, &.header-main__menu-item_selected{
		color: $color-orange !important;
		a{
			color: inherit;
		}
	}

	@at-root #{&}_shadow{
		text-shadow: 	$header-menu-shadow-x
									$header-menu-shadow-y
									$header-menu-shadow-size
									$header-menu-shadow-color;
	} //_shadow

	@at-root #{&}_space-reduced{
		padding-#{$left}: $header-menu-space-reduced / 2;
		padding-#{$right}: $header-menu-space-reduced / 2;
	} //_space-reduced

	@at-root #{&}_border{
		@at-root #{&}_none{
			border: none !important;
		} //_none
	} //_border_none

	@at-root #{&}_color{
		border-top: 9px solid transparent;
		transition: .2s;

		@at-root #{&}_orange{
			@include menu-item-color($color-orange);
		} //_orange
		@at-root #{&}_turquise{
			@include menu-item-color($color-turquise);
		} //_turquise
		@at-root #{&}_fuxia{
			@include menu-item-color($color-fuxia);
		} //_fuxia
		@at-root #{&}_green{
			@include menu-item-color($color-green);
		} //_green
		@at-root #{&}_blue{
			@include menu-item-color($color-deep-blue);
		} //_blue
		@at-root #{&}_red{
			@include menu-item-color($color-red);
		} //_red
	} //_color

} //header-main__item
.header-main__menu-item_parent{
  position: relative;

	&:after{
    content: '';
		border-#{$right}: 1px solid #fff;
		border-bottom: 1px solid #fff;
		border-top: 1px solid transparent;
		border-#{$left}: 1px solid transparent;
    width: 6px;
    background-color: transparent;
    height: 6px;
    position: absolute;
    top: 13px;
    #{$left}: 5px;
    transform: rotate(45deg);
  }

  &:hover{
    & > .header-main__child-menu{
    	display: block;
    }
  } //hover
} //header-main__item_parent

.header-main__child-menu{
	position: absolute;
	display: none;
	top: 44px;
	right: 0px;
	//padding: 15px 0;
  box-shadow: 0 0 20px rgba(0,0,0,.15);
  transition: .2s;

  &:after{
    width: 100%;
    content: '';
    height: 15px;
    top: -15px;
    left: 0;
    position: absolute;
  } //after

	&:hover{
		display: block;
	}
}

.header-main__child-item{
	display: block;
	margin-bottom: 0px;
	background-color: #fff;
	padding: 2px 20px;
	border-radius: 0;
	line-height: 40px;
	border: solid 1px rgba(0,0,0,0.2);
	border-bottom: 2px solid transparent;
	width: 300px;
  transition: .2s;

	&:hover{
		//background-color: #f9f9f9;

		a{
			color: $color-orange !important;
		}
	} //HOVER

	&:not(:first-of-type){
		border-top-color: transparent;
	} //not 1st

	a{
		color: #000 !important;
    font-size: 18px;
    //border-bottom: 2px solid #bfbfbf;
    display: block;
    width: 100%;

	} //a
} //__child-item

//Header for mobile devices
.header-mobile{
  display: none;

  @include respond-to('medium'){
    display: block;
  }

  .header-bg{
    height: 70px;
    background: linear-gradient(to #{$right},#f0521a 60px,#000 60px,#000 100%);
  }

  &__phone{
    position: relative;
    font-size: 40px;
    margin-#{$left}: 30px;
    margin-top: 8px;
  }

  .header-mobile-logo{
    width: 40%;
    max-width: 150px;
    display: block;
    position: absolute;
    #{$right}:0;
    top: 7px;

    img{
      margin-#{$left}: 10px;
    } //img
  } //header-mobile-logo

  .mobile-menu-btn{
    margin-top: 18px;
    margin-#{$left}: 10px;
  }
} //header-mobile

.header-links{
  padding-top: 30px;
  &__list-item{
    border: 1px solid transparent;
    display: inline-block;
    padding: 0 10px;
    a{
      color: $color-lilach;
      font-size: $header-font-size-medium;
      font-weight: 300;
    }
    &_framed{
      border-color: $color-lilach;
    } //_frame
  } //__list-item
} //header-links

.header-icn-link{
  float: $left;
  width: 32%;
  margin-top: 25px;

  @include respond-to('large'){
    width: 33% !important;
    float: $right;
    margin-top: 0;
  }

  &:first-of-type{
    width: 40%;
  }
  &:last-of-type{
    width: 28%;
  } //last-of-type
  &__text{
    float: $left;
    max-width: 70%;

    @include respond-to('large'){
      float: $right;
      margin-#{$left}: 10px;
    }
    span{
      font-size: $header-font-size-small;
      font-weight: 300;
      color: $color-steel-grey;
      display: block;
      line-height: 18px;
      &:last-of-type{
        font-size: $header-font-size-big;
        color: $color-lilach;
      } //last
    } //span
  } //__text
  img{
    float: $left;
    max-width: 30%;
    margin-top: 5px;
    margin-#{$left}: 5px;

    @include respond-to('large'){
      float: $right;
    }
  } //img
} //header-icn-link
