.article-block{
  //width: 960px;
  height: 220px;
  box-shadow: 0 0 25px rgba(0,0,0,0.1);
  padding: 20px;
  transition: .2s;
  margin-bottom: 20px;
  position: relative;

  @at-root #{&}__action{
    color: #000;
    position: absolute;
    bottom: 10px;
    font-size: 16px;
    #{$right}: 315px;
  } //__action

  img{
    float:$right;
    margin-#{$left}: 30px;
    max-height: 100%;
    width: 265px;
  }

  span{
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 900;
    display: block;
  }
  p{
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
  }
}

.articles-title{
  margin-bottom: 30px;
  font-size: 70px;
  font-weight: 400;
  letter-spacing: -.06em;

  @include respond-to('small'){
    margin-bottom: 10px;
    font-size: 40px;
  }
} //articles-title

.article-contact{
  box-shadow: 0 0 25px rgba(0,0,0,0.1);
  margin-top: 30px;
  padding-top: 15px;

  span{
    display: block;
    line-height: 20px;

    &:first-of-type{
      font-size: 16px;
      font-weight: 400;
    }
    &:last-of-type{
      font-size: 24px;
      font-weight: 800;
      margin-bottom: 20px;
    }
  }
  @at-root #{&}_color{
    @at-root #{&}_green{
      @include form-color($color-green);
    }
  }
  @at-root #{&}__form{
    width: 100%;
    padding: 15px;

    @include ph-color(#777)
    @include ph-size('16px');
  } //__form
  @at-root #{&}__input{
    width: 100%;
    margin-top: 10px;
    padding-bottom: 10px;
    border: none;
    border-bottom: 1px solid #e0e0e0;
  } //__input
  @at-root #{&}__submit{
    height: 40px;
    color: #fff;
    margin-top: 20px;
    padding-bottom: 0;
  }

  @at-root #{&}__area{
    resize: none;
    height: 65px;
  }
} //article-contact

.article-min-height{
  min-height: 1500px;

  @at-root #{&}_extra10{
    min-height: 1510px;
  }
}
