.home-content{

} //home-content

.home-strip{
  width: calc(50% - 150px);
  height: 1px;
  background-color: $color-silver;
  float:$right;
  position: relative;
  top: 17px;
}

.brand-img{
  float:$right;
  position: relative;

  &:not(:last-of-type){
    margin-#{$left}: 6%;
  }
}

.content-side{
  margin-#{$right}: 660px;
  width: calc(100% - 560px);
  position: relative;

  p{
    font-size: 18px;
    color: #504a48;
    line-height: 28px;
    text-align: justify;
  }
} //content-side

.content-title{
  text-align:$right;
  margin: 60px 0;
  width: 100%;

  h1{
    color: #393939;
    font-size: 50px;
    font-weight: 800;
    padding:0;
  }
} //content-title

.content-text{
  text-align: justify !important;
  font-size: 18px !important;
  color: #000;
  padding-#{$left}: 90px;
  padding-bottom: 50px;
  width: 385px;
  float:$right;
}

.content-image{
  float:$right;
}

.menu-side{
  width: 250px;
  position: relative;
  margin-#{$right}: 35px;

  ul{
    width: 100%;
    border-top: solid 1px silver;

    li{
      border-bottom: solid 1px silver;
      width: 100%;
      padding: 12px 0 13px;

      a{
        font-size: 18px;
        font-weight: 600;
        color: $color-grey;
        width: 100%;
        display: block;
      } //a

      &.content-item-selected, &:hover{
        border-bottom: solid 2px $color-green;
        padding-bottom: 12px;

        a{
          color: $color-green;
        } //a
      } //content-item-selected
    } //li
  } //ul

  h2{
    font-size: 26px;
    color: #000;
    font-weight: 600;
    margin-top: 75px;
    padding-bottom: 20px;
  } //h2
} //menu-side

.custom-form{
  .form-control{
    border-radius: 0;
    box-shadow: none;
    padding-#{$right}: 15px;
    height: 40px;

    @include placeholder{
      color: #777;
      font-size: 16px;
    }

  }
  .btn-form{
    border-radius: 0;
    height: 50px;
    font-size: 28px;
    line-height: 50px;
    font-weight: 400;
    padding: 0;
  }
  textarea.form-control{
    min-height: 100px;
  }
}
