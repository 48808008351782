.main-footer{
  width: 100%;
  position: relative;
  bottom: 0;
  background-color: #000;
  background: linear-gradient(to left, $color-orange 38%, #000 38%, #000 100%);
  padding: 70px 0;
  z-index: 0;

  @include respond-to('small'){
    padding: 15px;
    background: linear-gradient(to bottom, $color-orange 18%,#000 18%,#000 100%);
  }

  @at-root #{&}_padding{
    @at-root #{&}_15{
      padding: 0 15px;
    } //_15
  } //_padding

  @at-root #{&}__rights{
    direction: $dir;
    position: relative;
    top: 35px;

    span{
      color: #fff;
      font-size: 16px;
      font-weight: 300;
    } //span

    @include respond-to('medium'){
      top: unset;
      bottom: 10px;
    }
    @include respond-to('small'){
      float: none !important;

      span{
        font-size: 12px;
      } //span
      img{
        float: #{$right};
      }
    } //---small
  } //__right
} //main-footer

.footer-contact{
  @at-root #{&}__form {
    @include ph-color(#a19999);
    @include ph-size(16px);
    max-width: 300px;
    position: relative;
    float: $left;

    @include respond-to('small'){
      padding: 30px 15px;
      max-width: unset;
      float: none;
    }
    @include respond-to('large'){
      width: unset;
    } //---large
  } //__form
  @at-root #{&}__title {
    color: $color-orange;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: -.04em;
    margin-bottom: 30px;
    display: block;

    @include respond-to('small'){
      font-size: 18px;
      padding-bottom: 15px;
      padding-top: 15px;
      margin-bottom: 0;
    }
  } //__title
  @at-root #{&}__input {
    width: 100%;
    background-color: #fff;
    height: 40px;

    @include respond-to('small'){

    }
  } //__input
  @at-root #{&}__input-text {
    //border: 1px solid #555;
    padding-#{$right}: 15px;
    margin-bottom: 10px;
    color: #000;
    font-weight: 400;

    @include respond-to('small'){
      margin-bottom: 0px;
    }
  } //__input-text
  @at-root #{&}__submit {
    background-color: $color-orange;
    color: #fff;
    border: none;
    height: 50px;
    font-size: 28px;
    font-weight: 400;
    line-height: 50px;
    margin-top: 20px;
  } //__input-submit
  @at-root #{&}__checkbox {
    opacity: 0;

    &:checked{
      &+label{
        &:after{
          opacity: 1;
          transform: rotate(-45deg) scale(1);
        } //after
      } //label
    } //checked

  } //__checkbox
  @at-root #{&}__checkbox-label {
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    margin-#{$right}: 10px;
  } //__checkbox-label
  @at-root #{&}__checkbox-controller{
    &:before{
      content: '';
      width: 20px;
      height: 20px;
      border: 1px solid #555;
      display: block;
      position: absolute;
      #{$right}: 0px;
      top: 175px;
    } //before
    &:after{
      content: '';
      position: absolute;
      #{$right}: 4px;
      top: 180px;
      transform: rotate(-30deg) scale(.75);
      border-bottom: solid 2px #fff;
      border-left: solid 2px #fff;
      width: 12px;
      height: 7px;
      opacity: 0;
      transition: .25s;
    } //after
  } //__checkbox-controller
} //footer-contact

.footer-links{
  padding-#{$right}: 100px;

  @include respond-to('small'){
    padding-#{$right}: 0px;
    padding-top: 50px;
  } //---small
  ul{
    li{
      @include respond-to('small'){
        //display: inline-block;
      //  width: 48%;
      } //---small
    }
  }

  @at-root #{&}__title {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 20px;
    display: block;
    color: #e3e3e3;
  } //__title
  @at-root #{&}__border {
    width: 100%;
    height: 1px;
    overflow: hidden;
    background-color: #555;
    margin-top: 20px;
    position: relative;
    @at-root #{&}_highlight {
      width: 200%;
      height: 100%;
      background-color: $color-green;
      background: linear-gradient(to right, $color-green 0, $color-green 50%, transparent 100%);
      position: absolute;
      top: 0;
      #{$left}: 0;
      transform: translateX(-100%);
      transition: 2s;
    } //_highlight
  } //__border
  ul {
    li {
      color: $color-green;
      font-weight: 300;
      font-size: 14px;
      //line-height: 30px;

      a{
        color: #fff;
        font-weight: 300;
        font-size: 16px;
        line-height: 34px;
        letter-spacing: .02em;

        @include respond-to('small'){
          line-height: 20px;
          font-size: 14px;
        }

        &:hover{
          color: $color-orange;
        } //hover
      } //a
    } //li
  } //ul
} //footer-links

.footer-contacts{
  margin-top: 10px;
  border-top: 1px solid #fff;
  margin-left: 25px;
  padding-top: 20px;

  @include respond-to('small'){
    padding-top: 20px;
    margin-left: unset;
    text-align: center;
  }
  span, a{
    //width: 25%;
    //float: $right;
    color: #fff;
    font-weight: 300;
    font-size: 18px;
    line-height: 34px;
    display: inline-block;

    @include respond-to('small'){
      font-size: 16px;
      text-align: center;
    }
  /*&:nth-of-type(even)*/


  } //span, a

  &__padding_right{
    direction: ltr;
    text-align: $right;
    padding-#{$right}: 20px;

    @include respond-to('small'){
      text-align: center;
      padding-#{$right}: 10px;
    }
  } //EVEN
  &__separator{
    padding: 0 15px;

    @include respond-to('small'){
      padding: 0 5px !important;
    }
  } //__separator

  span{
    &:nth-of-type(odd){
      padding-#{$right}: 30px;

      @include respond-to('small'){
        padding-#{$right}: 0px;
      }
    }
  }
  a{
    color: #fff;

    &:hover{
      color: $color-orange;
    }
  } //a

} //footer-contacts

.direction-rtl{
  direction: rtl !important;
  padding-#{$right}: 30px;

  @include respond-to('small'){
    padding-#{$right}: 0px;
  }
}

.main-footer__soc{
  direction: $dir;
  position: relative;
  text-align: center;
  display: inline-block;
  width: 45%;
//  margin-#{$right}: 15px;
  a{
    padding-#{$left}: 15px;
    width: unset;
    i{
      color: #fff;
      transition: .2s;
    } //i

    &:hover{
      i{
        color: $color-orange;
      } //i
    } //hover
  } //a
  @include respond-to('medium'){
    top: unset;
    bottom: 10px;
  }
  @include respond-to('small'){
    float: none !important;
    bottom: 0;
  } //---small
  @include respond-to('extra-small'){
    #{$left}: 0;
  }
} //__soc

.padding{
  &_left-5{
    padding-#{$left}: 5px;
    @include respond-to('small'){
      padding-#{$left}: 15px;
    }
  }
  &_right-5{
    padding-#{$right}: 5px;
    @include respond-to('small'){
      padding-#{$right}: 15px;
    }
  }
}


.cs_logo_png{
  position: relative !important;
  #{$left}: 0 !important;
  #{$right}: unset !important;
  bottom: unset !important;
  top: 20px !important;
  margin: 0 !important;
  transform: none !important;

  @include respond-to('medium'){
    position: absolute !important;
    top: unset !important;
    bottom: 10px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  } //---small
}
