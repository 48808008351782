.main-banner{
  position: relative;
  //height: 90vh;
  margin-top: 100px;
  //max-height: 70vh;
  //min-height: 600px;

  @include respond-to('medium'){
    margin-top: 70px;
    max-height: 50vh;
  } //---small

  &__single{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 900px;
    //max-height: 75vh;
    img{
      display: none;
    }
  } //__single

  &__section{
    width: 1400px;
    height: 100%;
    padding: 30px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  } //__section

  &__texts{
    //top: 70px !important;
    #{$right}: calc(50% - 580px);
    position: absolute;


    @include respond-to('large'){
      //#{$right}: 0;
      transform: translateX(-50%);
      left: 50%;
      right: unset;
      top: 100px !important;
      width: 80%;
      max-width: 600px;
    }

    &:before{
      content: '';
      width: 430px;
      height: 2px;
      background-color: $color-orange;
      position: absolute;
      bottom: -230px;
      #{$right}: -60px;
      transform: rotate(-45deg);
      z-index: 1;

      @include respond-to('large'){
        display: none;
      }
    }

    &:after{
      content: '';
      width: 480px;
      height: 2px;
      background-color: $color-orange;
      position: absolute;
      bottom: -140px;
      #{$right}: -100px;
      transform: rotate(-45deg);
      z-index: 1;

      @include respond-to('small'){
        width: 150px;
        bottom: 25px;
      }
    }
  } //__texts

  &__title{
    font-size: 36px;
    font-weight: 300;
    color: #fff;
    padding: 10px 30px 15px;
    margin-bottom: 0px;
    display: inline-block;
    background-color: #000;
    letter-spacing: .05em;
    display: inline-block;

    @include respond-to('small'){
      font-size: 25px;
      padding: 5px 15px 7px;
    }
    //line-height: 50px;
  } //__title

  &__sub{
    font-size: 19px;
    font-weight: 400;
    line-height: 28px;
    color: #fff;
    text-align: $right;
    padding: 20px 30px;
    width: 600px;
    display: block;
    max-width: 80%;
    position: relative;
    background-color: $color-orange;

    @include respond-to('small'){
      font-size: 16px;
      padding: 10px 15px;
      line-height: 18px;
    }

    &:after{
      content: '';
      width: 113px;
      height: 2px;
      background-color: $color-orange;
      position: absolute;
      bottom: -40px;
      #{$left}: -90px;
      transform: rotate(-45deg);
      z-index: 1;

      @include respond-to('small'){
        bottom: -20px;
        width: 70px;
        #{$left}: -50px;
      } //after
    }
  } //__sub

  span{
    font-size: 18px;
    white-space: pre;
    font-weight: 300;
    color: $color-lilach;
    border: 1px solid $color-lilach;
    padding: 5px 15px;
    position: absolute;
    bottom: -30px;
    transform: translateY(100%);
    right: 0;
  }

} //main-banner

.home-content{
  padding-top: 130px;
  min-height: 800px;

  @include respond-to('huge'){
    padding-left: 15px;
    padding-right: 15px;
  }

  @include respond-to('small'){
    padding-top: 0;
    min-height: unset;
  }
  &__text{
    padding-#{$left}: 40px;
    h3{
      font-size: 25px;
      font-weight: 700;
      color: #000;
      letter-spacing: -.02em;
      margin: 10px 0;

      @include respond-to('small'){
        font-size: 18px;
        margin: 5px 0;
      }
    } //h3
    p{
      font-size: 20px;
      font-weight: 400;
      color: #000;
      letter-spacing: -.02em;
      line-height: 35px;

      @include respond-to('small'){
        font-size: 16px;
        line-height: 25px;
      }
    } //p
  } //__text

  &__title{
    font-size: 70px;
    font-weight: 400;
    color: #000;
    letter-spacing: -.06em;
    margin: 0 0 60px;

    @include respond-to('huge'){
      //padding-left: 15px;
      //padding-right: 15px;
    }
    @include respond-to('small'){
      font-size: 25px;
      margin: 0 0 20px;
    }
  } //__title

  &__img{
    position: relative;
    margin-top: 45px;
    img{
      position: relative;
      z-index: 2;

      @include respond-to('huge'){
        max-width: 80%;
        margin: 0 auto 0 60px;
        display: block;
      }
    } //img
  } //__img

  &__decoration{
    width: 460px;
    height: 140px;
    background-color: $color-orange;
    transform: rotate(-45deg);
    position: absolute;
    #{$left}: -20px;
    top: 130px;
    z-index: 1;

    @include respond-to('huge'){
      width: 45%;
      height: 50%;
      transform: rotate(-45deg) translate(-15%, 50%);
      left: 0;
      top: 0;
    }

    &:before, &:after{
      content: '';
      background-color: $color-orange;
      transform: rotateX(-45deg);
      position: absolute;
    }

    &:before{
      width: 350px;
      height: 670px;
      top: 40px;
      #{$left}: 0;

      @include respond-to('huge'){
        width: 80%;
        height: 150%;
        transform: translate(0%, 15%);
        top: 0;
        #{$left}: 0;
      }
    } //before

    &:after{
      width: 140px;
      height: 2px;
      top: 540px;
      #{$left}: -140px;

      @include respond-to('huge'){
        width: 140px;
        height: 100px;
        top: 0;
        transform: translate(-50%, 90%);
        background: linear-gradient(to bottom, transparent 98px, $color-orange);
        #{$left}: 0;
      }
    }
  } //__decoration

  &__menu{
    min-height: 1100px;

    @include respond-to('huge'){
      padding-left: 15px;
      padding-right: 15px;
    }
    @include respond-to('small'){
      min-height: unset;
    }

    h3{
      font-size: 30px;
      letter-spacing: .04em;
      font-weight: 400;
      color: #000;
      margin: 0 0 60px;

      @include respond-to('small'){
        font-size: 18px;
        margin: 0 0 10px;
      }
    } //h3
    ul{
      li{
        cursor: pointer;
        font-size: 20px;
        letter-spacing: .04em;
        height: 80px;
        padding-top: 25px;
        padding-#{$right}: 15px;
        position: relative;
        transition: 1s;

        @include respond-to('small'){
          height: 30px;
          font-size: 16px;
          float: $right;
          width: 48%;
          display: inline-block;
          margin-bottom: 30px;
        }

        &:hover, &.home-content__menu_selected{
          &:after{
            opacity: 1;
          }
        }

        &.home-content__menu_selected{
          padding-#{$right}: 60px;

          @include respond-to('small'){
            padding-#{$right}: 30px;
          }
        }

        &:after{
          content: '';
          border: 9px solid transparent;
          border-#{$right}: 15px solid $color-orange;
          position: absolute;
          #{$right}: -5px;
          top: 30px;
          transition: .5s;
          opacity: 0;
        }
      } //li
    } //ul
  } //__menu
} //home-content

.gallery-menu{
  h2{
    font-size: 30px;
    letter-spacing: .04em;
    font-weight: 400;
    color: #000;
    margin: 0 0 60px;

    @include respond-to('small'){
      font-size: 18px;
      margin: 0 0 10px;
    }
  }
}

.home-box{
  height: $home-box-height;
  margin-bottom: 40px;

  &__image-box{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  } //__image-box

  &_bg{
    &_blue{
      background-color: #006488;
    } //_blue
    &_green{
      background-color: $color-green;
    } //_green
    &_black{
      background-color: #000;

      h2{
        border-bottom-color: #fff;
      }
    } //_black
    &_img{
      background-repeat: no-repeat;
      background-position: right;
      padding-#{$right}: 360px;
      padding-#{$left}: 80px;
      padding-top: 80px;

      @include respond-to('large'){
        padding-#{$right}: 300px;
        padding-#{$left}: 30px;
        padding-top: 40px;
      } //---large
      @include respond-to('smaller'){
        padding: 0;
      }
    } //_img
  }  //_bg
  &__news{
    padding: 60px 60px 0;

    ul{
      li{
        height: 285px;
        position: relative;
      } //li
    } //ul

    @include respond-to('large'){
      padding: 70px 40px 0;
    } //--large
    @include respond-to('small'){
      padding: 50px 20px 0;
    }
  } //__news
  &__news-title{
    margin: 0;
    color: #000;
    font-size: 40px;
    padding-bottom: 40px;
    font-weight: 800;
    border-bottom: 3px solid #000;
    line-height: 40px;

    @include respond-to('large'){
      font-size: 30px;
      padding-bottom: 20px;
    } //---large
  } //__news-title
  &__news-item{
    padding-top: 40px;

    h3{
      font-size: 26px;
      font-weight: 800;
      color: #fff;
      margin: 0 0 30px;
    } //h3

    p{
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;
    } //p

    span{
      position: absolute;
      bottom: 40px;
      #{$right}: 0;
      color: #fff;
      font-weight: 300;
      font-size: 18px;
      border-top: 2px solid #fff;

      &:after{
        content: '>';
        margin-right: 5px;
      }
    } //span

    @include respond-to('large'){
      padding-top: 30px;

      h3{
        font-size: 22px;
      } //h3
      span{
        bottom: 0;
      } //span
    } //--large
  } //__news-item
  &__texts{
    color: #fff;
    font-weight: 300;

    @include respond-to('smaller'){
      position: absolute;
      bottom: 50px;
      padding: 0 15px;
    }
  } //__texts
  &__pre-title{
    font-size: 22px;

    @include respond-to('huge'){
      font-size: 20px;
    } //---huge
    @include respond-to('large'){
      font-size: 18px;
    } //---huge
    @include respond-to('smaller'){
      font-size: 16px;
    }
  } //__pre-title
  &__title{
    font-size: 50px;
    font-weight: 800;
    color: $color-green;
    margin: -10px 0 40px;

    @include respond-to('huge'){
      font-size: 40px;
      margin-bottom: 20px;
    } //---huge
    @include respond-to('large'){
      font-size: 30px;
    } //---huge
    @include respond-to('smaller'){
      font-size: 25px;
    }
  } //__title
  &__summary{
    font-size: 18px;
    line-height: 20px;

    @include respond-to('huge'){
      font-size: 16px;
    } //---huge
    @include respond-to('smaller'){
      font-size: 14px;
    }
  } //__summary
  &__action{
    font-size: 18px;
    margin-top: 20px;
    display: inline-block;
    border-top: solid 3px $color-green;

    @include respond-to('smaller'){
      font-size: 14px;
      margin-top: 5px;
    }

    &:after{
      content: '>';
    }
  } //__action
} //home-box

.home-jobs{
  &__ticker{
    position: relative;
  } //__ticker
  &__item{
    height: 55px !important;
    padding-top: 10px;
    h3{
      font-size: 20px;
      color: $color-green;
      font-weight: 300;
      margin: 0;

      @include respond-to('small'){
        font-size: 16px;
      }
    } //h3
    span{
      bottom: unset;
      border: none;
      font-size: 16px;
      line-height: 18px;

      &:after{
        content: '>';
      }
    } //span
  } //__item
  &__action{
    position: absolute;
    right: 75px;
    bottom: 70px;
    color: #fff;
    font-weight: 300;
    font-size: 18px;
    border-top: solid 2px #fff;

    &:after{
      content: ' >';
    }
  } //__action
} //home-jobs

.box{
  width: 100%;
  padding-top: $box-head-space;
  padding-bottom: $box-foot-space;
  position: relative;

  @at-root #{&}__strip{
    height: $box-strip-width;
    margin-top: $box-strip-top;

    @at-root #{&}_silver{
      background-color: $color-silver;
    } //_silver
  } //__strip

  @at-root #{&}__title{
    text-align: center;
    position: relative;
    font-size: $box-title-size;
    padding: 0 0 40px;

    @at-root #{&}_has-separator{
      &:after{
        width: 100%;
        content: '';
        height: 2px;
        box-sizing: border-box;
        border-top: 1px solid #000;
        border-bottom: 1px solid #fff;
        position: absolute;
        bottom: 0;
        #{$left}: 0;
        opacity: .15;
      }
    }

    @at-root #{&}_strong{
      font-weight: 700;
    }
  } //__title

  @at-root #{&}__sub-title{
    font-size: $box-subtitle-size;
    text-align: center;
    position: relative;
  } //__sub-title

  @at-root #{&}__content{
    font-size: $box-content-size;
    line-height: $box-content-line;
    position: relative;

    p{
      font-size: inherit;
      margin-bottom: $box-content-p-bottom;
    } //p
    @at-root #{&}_block{
      @at-root #{&}_s{
        width: $box-content-block-small;
        min-width: $box-content-block-small-min;
      } //_s
      @at-root #{&}_m{
        width: $box-content-block-medium;
        min-width: $box-content-block-medium-min;
      } //_m
      @at-root #{&}_l{
        width: $box-content-block-large;
        min-width: $box-content-block-large-min;
      } //_l
      @at-root #{&}_full{
        width: 100%;
      } //_l
    } //_block
  } //__content

  @at-root #{&}__img{
    @at-root #{&}_height{
      @at-root #{&}_full{
        max-width: unset;
        width: auto;
        height: 100%;
        position: absolute;
        top: 0;
      } //_full
    } //_height
  } //__img

  @at-root #{&}__background{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    #{$left}: 0;
    z-index: -1;

    @at-root #{&}_color{
      @at-root #{&}_black{
        background-color: $color-black;
      } //_black
      @at-root #{&}_white{
        background-color: $color-white;
      } //_white
      @at-root #{&}_peach{
        background-color: $color-peach;
      } //_peach
    } //_color
    @at-root #{&}_opacity{
      @at-root #{&}_half{
        opacity: .5;
      } //_half
      @at-root #{&}_third{
        opacity: .33;
      } //_half
    } //_opacity
  } //__background

  @at-root #{&}_bg{
    @at-root #{&}_white{
      background-color: $color-white;
    } //_white
    @at-root #{&}_black{
      background-color: $color-black;
    } //_black
    @at-root #{&}_silver{
      background-color: $color-silver;
    } //_silver
    @at-root #{&}_cream{
      background-color: #f6f7ee;
    } //_cream
    @at-root #{&}_deep-blue{
      background-color: $color-deep-blue;
    } //_deep-blue
    @at-root #{&}_water-blue{
      background-color: $color-water-blue;
    } //_deep-blue
    @at-root #{&}_aqua{
      background-color: $color-aqua;
    } //_aqua
    @at-root #{&}_green{
      background-color: $color-green;
    } //_green
    @at-root #{&}_navy-blue{
      background-color: $color-navy-blue;
    } //_deep-blue
    @at-root #{&}_img{
      background-image: url($box-bg-img);
    } //_img
  } //_bg

  @at-root #{&}_border{
    @at-root #{&}_grey{
      border: solid $box-border-width $color-grey;
    } //_grey
  } //_border

  @at-root #{&}_align{
    @at-root #{&}_center{
      text-align: center;
    } //_center
  } //_align
} //box

.mobile-no-padding{
  @include respond-to('small'){
    padding-top: 0;
  }
}

.buttons-container{
  display: block;
  position: relative;
  text-align: center;
  margin-#{$left}: $button-spacing * -1;
  margin-#{$right}: $button-spacing * -1;
  padding-top: $button-head-space;

  @at-root #{&}__button{
    display: inline-block;
    margin: 0 $button-spacing / 2;
    width: $button-width;
  } //button
  @at-root #{&}__icon{
    display: block;
    margin: $center-margin;
  } //icon
  @at-root #{&}__title{

  } //title

  @at-root #{&}_padding-top{
    @at-root #{&}_small{
      padding-top: $buttons-container-head-space;
    } //_small
  } //_margin-top

  @at-root #{&}_padding-bottom{
    @at-root #{&}_small{
      padding-bottom: $buttons-container-foot-space;
    } //_small
  } //_padding-bottom

  @at-root #{&}_bg-color{
    @at-root #{&}_silver{
      background-color: $color-silver;
    } //_silver
  } //_bg-color
} //buttons-container

.slider-container{
  text-align: center;
  position: relative;
  margin-top: $slider-head-space;

  @at-root #{&}__item{
    padding: $slider-item-head-space $padding-base $slider-item-foot-space;
    position: relative;

    @at-root #{&}_size{
      @at-root #{&}_m{
        width: $slider-item-width;
        height: $slider-item-height;
      } //_m
    } //_size

    @at-root #{&}_onsale{
      &:after{
        content: url(/assets/images/onsale.png);
        position: absolute;
        top: 0;
        #{$left}: 0;
      } //after
    } //_onsale
  } //__item
  @at-root #{&}__image{
    display: block;
    margin: $center-margin;
  } //image
  @at-root #{&}__text{
    position: absolute;
    bottom: $slider-item-foot-space;
    width: 100%;
    margin-#{$left}: $padding-base * -1;
    margin-#{$right}: $padding-base * -1;
  } //__text
  @at-root #{&}__title{
    font-size: $slider-title-size;
    color: $slider-title-color;
    position: relative;

  } //__title
  @at-root #{&}__price{
    position: relative;
    font-size: $slider-price-size;
    color: $slider-price-color;
  } //__price
  @at-root #{&}__overlay{
    position: absolute;
    top: 0;
    #{$left}: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    transition: .2s;

    &:hover{
      opacity: 1;
    } //hover
  } //__overlay

  @at-root #{&}__full-transparent{
    position: absolute;
    top: 0;
    #{$left}: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: $slider-hover-opacity;
    transition: .2s;
  } //__full-transparent
} //slider-container


.home-forms{
  width: 27%;
  position: absolute;
  #{$left}: 0;
  top: 150px;


  @at-root #{&}_bg{
    @at-root #{&}_aqua{
      background-color: $color-aqua !important;
    } //_aqua
  } //_bg
  @at-root #{&}_color{
    @at-root #{&}_aqua{
      color: $color-aqua !important;
    } //_aqua
  } //_bg

  @at-root #{&}__form{
    background-color: #fff;
    width: 360px;
    padding: 30px;
    padding-top: 45px;
    padding-bottom: 55px;
    margin-bottom: 25px;
    text-align: center;
    position: relative;
    //float:$left;
    @include ph-color('#a19999');
    @include ph-size('16px');
  } //__form
  @at-root #{&}__title{
    color: #fff;
    font-size: 18px;
    line-height: 28px;
    position: absolute;
    top: 0;
    #{$left}: 0;
    width: 100%;
    height: 50px;
    background-color: #6e2492;
    padding-top: 10px;

    span{
      &:first-of-type{
        font-size: 18px;
        font-weight: 500;
      } //last
    } //span
  } //__title
  @at-root #{&}__components{
    @at-root #{&}_margin-top{
      @at-root #{&}_none{
        margin-top: 0 !important;
      } //_none
    } //_margin-top
    @at-root #{&}_width{
      @at-root #{&}_full{
        width: 100% !important;
      } //_full
    } //_width
    select{
      background-color: #efeeee;
      margin-bottom: 40px;
      border: solid 1px #dcdcdc;
      height: 35px;
    } //select
    input{
      &[type='text']{
        width: 46%;
        float:$right;
        height: 35px;
        border: solid 1px #dcdcdc;
        padding-#{$right}: 12px;
        margin-bottom: 20px;
        margin-top: 35px;
        background-color: #efeeee;

        &:first-of-type{
          margin-#{$left}: 8%;
        }
      } //text
      &[type='email']{
        width: 100%;
        height: 35px;
        border: solid 1px #dcdcdc;
        padding-#{$right}: 12px;
        margin-bottom: 40px;
        background-color: #efeeee;

      } //email
      &[type='submit']{
        width: 100%;
        text-align: center;
        color: #6e2492;
        height: 50px;
        font-size: 28px;
        box-shadow: 1px 3px 5px rgba(0,0,0,.2);
        border: solid 1px #cbcaca;
        background-color: #fff;

      } //submit
    } //input
  } //__components
} //home-form

.slick-dots{
  top: 82vh;
  z-index: 1;

  li{
    &.slick-active{
      button{
        background-color: #000;
      }
    } //slick-active
    button{
      border-radius: 50%;
      background-color: #cdd3cd;
      border: none;
    } //button
  } //li
} //slick-dots

.home-button{
  display: block;
  transition: .3s;
  box-shadow: 0 0 20px rgba(0,0,0,.15);
  height: ($home-box-height - $home-box-btn-space) / 2;
  margin-bottom: $home-box-btn-space;
  padding-top: 40px;

  &:active{
    position: relative;
    top: 1px;
    #{$left}: 1px;
    box-shadow: 0 0 5px rgba(0,0,0,.3);
  }

  svg{
    display: block;
    margin: 0 auto;
  }

  span{
    text-align: center;
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-bottom: 30px;
    font-size: 22px;
    font-weight: 800;
    color: $color-light-blue;
  } //span

  &:hover{
    background-color: $color-light-blue;

    span{
      color: #fff;
    } //span
    img{
      filter: invert(1);
    }
  } //hover

  @at-root #{&}__big-text{
    width: 400px;
    position: absolute;
    bottom: 45px;
    #{$left}: 35px;
    pointer-events: none;
  } //__big-text
  @at-root #{&}__big{
    color: #fff;

    &:hover{
      color: #fff;
    }

    p{
      color: inherit;
      font-size: 22px;
      font-weight: 300;
      letter-spacing: -.02em;
    } //p
  } //__big
  @at-root #{&}__link{
    font-size: 28px;
    letter-spacing: -.02em;
    float:$left;
  } //__link

  @at-root #{&}__texts{
    position: absolute;
    bottom: 0;
    #{$right}: 0;
    width: 100%;
    z-index: 3;
    padding: 40px;
    pointer-events: none;

    span{
      color: $color-light-blue;
      text-align: center;
      font-size: 22px;
      font-weight: 800;
    } //span
  } //__texts
  position: relative;
  img{
    display: block;
    position: relative;
    margin: 0 auto;
  } //img

  @at-root #{&}_effect{
    @at-root #{&}_hover-shadow{
      span, p{
        text-shadow: none;
        transition: .4s;
      } //span p


      &:hover{
        span, p{
          text-shadow: 4px 2px 10px black;
        } //span p
      } //hover
    } //_hover-shadow
  } //_effect
} //home-button

.home-title{
  font-size: 26px;
  font-weight: 800;
  padding-bottom: 30px;
  margin-bottom: 30px;
  display: block;
  position: relative;

  @at-root #{&}_line{
    @at-root #{&}_black{
      &:after{
        background-color: #000 !important;
      } //after
    } //_black
  } //_line

  &:after{
    content: '';
    background-color: #fff;
    position: absolute;
    bottom: 0;
    #{$left}: 0;
    height: 1px;
    width: 100%;
  }
}

.fill{
  @at-root #{&}_orange{
    @include svg-fill-color($color-orange);
  } //_orange
  @at-root #{&}_green{
    @include svg-fill-color($color-green);
  } //_green
  @at-root #{&}_turquise{
    @include svg-fill-color($color-turquise);
  } //_turquise
  @at-root #{&}_blue{
    @include svg-fill-color($color-deep-blue);
  } //_blue
  @at-root #{&}_fuxia{
    @include svg-fill-color($color-fuxia);
  } //_fuxia
  @at-root #{&}_red{
    @include svg-fill-color($color-red);
  } //_red
  @at-root #{&}_white{
    @include svg-fill-color($color-white);
  } //_white
} //fill

.home-contact{
  height: 80px;
  padding: 10px;
  position: relative;

  @include respond-to('medium'){
    height: 210px;
  }
  @include respond-to('small'){
    height: 310px;
  }

  &:after{
    content: url(../images/form_arrow.png);
    position: absolute;
    top: -6px;
    #{$right}: 450px;
  }

  &__titles{
    width: 21%;
    float: $right;

    @include respond-to('small'){
      width: 100%
    }
    h1{
      margin: 0;
      font-size: 30px;
      font-weight: 300;
      line-height: 28px;
    }
    span{
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
    }
  } //__titles
  &__inputs{
    width: 79%;
    float: $right;
    padding-top: 12.5px;

    @include respond-to('small'){
      width: 100%
    }

  } //__inputs
  &__form{
    @include ph-color(#fff);
    @include ph-size(18px, 300);
  } //__form
  &__input{
    height: 35px;
    background-color: #99bf54;
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    padding-#{$right}: 10px;
    border: 1px solid #86a84a;
    border-bottom-color: #ebffc7;
    width: 100%;

    @include respond-to('medium'){
      margin-bottom: 10px;
    }
  } //__input
  &__submit{
    background-color: $color-lilach;
    border: none;
    padding-#{$left}: 6px;
  } //__submit
} //home-contact

.home-btn{
  text-align: center;
  height: 580px;
  transition: .2s;
  margin-bottom: 45px;

  @include respond-to('small'){
    height: 400px;
    margin-bottom: 15px;
  }

  &__title{
    font-size: 30px;
    font-weight: 400;
    display: block;
    color: #000;
    letter-spacing: -.02em;
    position: absolute;
    bottom: 35px;
    width: 45%;
    min-width: 215px;
    left: 50%;
    transform: translateX(-50%);

    @include respond-to('small'){
      font-size: 25px;
      bottom: 60px;
    }
  } //__title
  img{
    position: absolute;
    display: block;
    bottom: 135px;
    left: 50%;
    transform: translateX(-50%);

    @include respond-to('small'){
      bottom: 135px;
      max-height: 75%;
    }
  } //img
} //home-btn

.home-proj{
  position: relative;
  height: 435px;
  margin-bottom: 30px;
  overflow: hidden;

  @include respond-to('small'){
    height: 300px;
  }

  &:hover{
    img{
      min-width: 110%;
      min-height: 110%;
      transition: 5s;
      transform: translate(-50%, -50%) rotate(3deg);
    }
    .home-proj__overlay{
      opacity: 1;
    }
  }

  img{
    min-width: 100%;
    min-height: 100%;
    transition: .5s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__overlay{
    background-color: rgba(0,0,0,.75);
    height: 50%;
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    padding-top: 45px;
    padding-#{$right}: 45px;
    padding-#{$left}: 100px;
    padding-bottom: 25px;
    opacity: 0;
    transition: 1s;
  } //__overlay
  &__title{
    color: #fff;
    font-size: 42px;
    letter-spacing: .04em;
    font-weight: 400;
    line-height: 35px;
    margin: 0 0 30px;
  } //__title
  &__text{
    font-size: 15px;
    line-height: 19px;
    font-weight: 400;
    letter-spacing: .04em;
    color: #fff;
    position: absolute;
    bottom: 10px;
    padding-#{$left}: 100px;
  } //__text
  &__btn{
    width: 65px;
    height: 65px;
    text-align: center;
    position: absolute;
    #{$left}: 0;
    bottom: 0;
    padding-top: 3px;
    background-color: $color-orange;

    span{
      color: #fff;
      font-size: 45px;
      font-weight: 700;
      top: 50%;
      transform: translateY(-50%);
    }
  } //__btn
} //home-proj

.products-height{
  &__implement{
    @include respond-to('medium'){
      height: unset !important;
      margin-bottom: 30px;
    }
  }
}
.home-category{
  position: absolute;
  top: 0;
  #{$left}: 15px;
  width: calc(100% - 30px);
  opacity: 0;
  z-index: 0;
  transition: 1s;

  @include respond-to('small'){
    position: relative;
    #{$left}: 0;
    width: 100%;

    display: none;
  }

  &_selected{
    opacity: 1;
    z-index: 1;

    display: block;
  } //_selected
} //home-category

.sb-slidebar{
  z-index: 102;
}

.desktop.accessibility_wrapper{
  z-index: 101 !important;
}
