.content-banner{
  position: relative;
  height: 500px;
  max-height: 80vh;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 90px;

  @include respond-to('medium'){
    margin-top: 70px;
    height: 500px;
    max-height: 60vh;
    background-position: center;
    background-size: auto 100%;
  }
  @include respond-to('small'){
    max-height: 30vh;
  }

  @at-root #{&}__overlay{
    position: absolute;
    top: 0;
    #{$left}: 0;
    width: 100%;
    height: 100%;
    background-color: $color-black;
    opacity: .5;
  } //__overlay

  @at-root #{&}__text{
    max-width: 900px;
  } //__text

  @at-root #{&}__title{
    font-size: 28px;
    font-weight: 800;
    margin-bottom: 20px;
    text-align: center;
    position: absolute;
    z-index: 0;
    top: 50%;
    #{$left}: 50%;
    transform: translate(-50%, -50%);
  } //__title

  @at-root #{&}__sub{
    font-size: 30px;
    font-weight: 400;
  } //__sub

  @at-root #{&}__img{
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    //background-attachment: fixed;
  } //__img
} //content-banner

.content-head{
  margin-top: 220px;

  @include respond-to('medium'){
    margin-top: 130px;
  }
} //content-head

.content{
  @at-root #{&}__title{
    padding-bottom: 35px !important;
  }
  @at-root #{&}__h3{
    h3{
      font-weight: 900;
      font-size: 24px;
      color: $color-black;
    }
  }
}

.breadcrumb {
  margin: 0;
  color: #777;
  font-size: 15px;
  font-weight: 400;

  &>li{
    a{
      color: #000;
    } //a
    &+li{
      &:before{
        color: #000;
      } //before
    } //li
  } //li

  &>.active{
    color: #000;
  } //active
} //breadcrumb
.content-menu-side{
  width: 285px;
  margin-#{$right}: 20px;

  @include respond-to('large'){
    position: fixed;
    #{$right}: 0;
    top: 0px;
    box-shadow: 0 0 20px rgba(0,0,0,.2);
    max-width: 80%;
    background-color: #fff;
    margin: 0;
    height: 100%;
    max-height: unset;
    z-index: 10;
    padding: 100px 15px 0;
    transform: translateX(100%);
    transition: .3s;
  } //---medium
  &__btn{
    position: absolute;
    top: 170px;
    left: -30px;
    width: 60px;
    height: 60px;
    background-color: #fff;
    display: none;
    border-radius: 50%;
    border-left: 1px solid #aaa;

    &:after{
      content: '';
      border: 1px solid transparent;
      border-bottom-color: #000;
      border-left-color: #000;
      position: absolute;
      top: 26px;
      left: 10px;
      width: 8px;
      height: 8px;
      transform: rotate(45deg);
    }

    @include respond-to('large'){
      display: block;
    }
  } //__btn

  &_revealed{
    transform: translateX(0);
  }
} //content-menu-side

.content-menu{
  width: 100%;
  padding: 50px 0px 0px;

  span{
    font-size: 26px;
    font-weight: 300;
    color: $color-lilach;
  }

  @at-root #{&}__list{
    border-top: 1px solid #e0e0e0;
    width: 100%;
    margin-top: 30px;
  }

  @at-root #{&}__list-item{
    position: relative;
    border-bottom: 1px solid #e0e0e0;

    @at-root #{&}_active, &:hover {
      border-bottom-color: $color-lilach;
      a{
        color: $color-lilach !important;
      } //a
    } //_active

    a{
      color: #777;
      font-size: 18px;
      line-height: 40px;
      font-weight: 300;
      transition: .3s;
    } //a
  } //li
} //content-menu

.content-main{
  width: 100%;
  padding-bottom: 180px;

  &__title, h2{
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 70px;
    font-weight: 400;
    color: #000;
    letter-spacing: -.06em;

    @include respond-to('medium'){
      font-size: 30px;
      margin-bottom: 20px;
      margin-top: 0;
    }
  } //h1

  h2{
    font-size: 50px;

    @include respond-to('medium'){
      font-size: 25px;
    }
  }

  &__title{
    margin-top: 30px;

    @include respond-to('medium'){
      padding-left: 15px;
      padding-right: 15px;
    } //---medium
  }

  @at-root #{&}__text{


    @include respond-to('medium'){
      padding-left: 15px;
      padding-right: 15px;
    } //---medium

    h3{
      font-size: 25px;
      font-weight: 700;
      letter-spacing: -.02em;
      margin: 0 0 10px;
    }
    p{
      padding-#{$left}: 185px;
      margin-bottom: 80px;

      @include respond-to('medium'){
        padding-#{$left}: 15px;
      }
    } //p
    span{
      padding-#{$left}: 10px;
      margin-bottom: 80px;
    }
    p, span, li{
      font-size: 20px;
      line-height: 35px;
      font-weight: 400;
      color: #000;
      letter-spacing: -.02em;


      @include respond-to('medium'){
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 20px;
      }
    }
    li{
      padding-#{$left}: 185px;
      padding-#{$right}: 20px;
      position: relative;
      margin-bottom: 15px;

      @include respond-to('medium'){
        padding-#{$left}: 15px;
      }

      &:after{
        content: '';
        width: 8px;
        height: 8px;
        background-color: $color-orange;
        position: absolute;
        #{$right}: 2px;
        top: 13px;
        transform: rotate(45deg);

      } //after
    } //li
  } //__text
} //content-main

.content-menu-side{

}

.content-min-height{
  min-height: 1030px;

  @at-root #{&}_extra10{
    min-height: 1040px;
  }
}

.content-icn-menu{
  margin-top: 40px;

  @at-root #{&}__list{

  } //__list
  @at-root #{&}__list-item{
    position: relative;
    height: 60px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,.05);
    transition: .3s;
    margin-bottom: 10px;

    &:hover{
      span{
        color: #fff !important;
      }
    }

    svg{
      height:40px;
      width:40px;
      float:$right;
    } //svg
    span{
      display: block;
      float:$right;
      margin-#{$right}: 24px;
      line-height: 20px;
      transition: .3s;

      &:first-of-type{
        font-size: 18px;
        font-weight: 900;
      } //1st
      &:last-of-type{
        color: #777;
        font-size: 16px;
        font-weight: 400;
      } //last
    } //span
  } //__list-item
} //content-icn-menu

.content-news{
  margin-top: 100px;
  &__title{
    font-size: 26px;
    font-weight: 300;
    color: $color-lilach;
    padding-bottom: 20px;
    margin: 0;
  } //__title
} //content-news

.search-results{
  padding: 0 15px;

  @include respond-to('small'){
    padding: 0;
  } //---small

  @at-root #{&}__title{
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 10px;

    span{
      font-size: 30px;
      color: #000;
      font-weight: 400;

      &:nth-of-type(2){
        font-weight: 900;
      } //2nd
    } //span
  } //__title
  @at-root #{&}__headers{
    height: 60px;
    width: 100%;

    @include respond-to('small'){
      display: none;
    }

    span{
      font-size: 18px;
      font-weight: 300;
      color: #000;
      text-align: center;
      display: block;
      height: 100%;
      width: 21%;
      float: right;
      padding-top: 15px;

      &:first-of-type{
        width: 70%;
      } //1st
      &:last-of-type{
        width: 9%;
      } //last
    } //span
  } //__headers
} //search-results

.content-form{
  padding: 25px 35px;
  position: relative;

  @include ph-color($color-lilach);
  @include ph-size(18px, 300);

  &:after{
    content: url(/assets/images/form_tag.png);
    position: absolute;
    top: -7px;
    #{$left}: -7px;
  } //after
  &__input{
    border: none;
    margin-top: 30px;
    width: 100%;
  } //__input
  &__input-text{
    border-bottom: 1px solid $color-lilach;

    &:first-of-type{
      margin-top: 0;
    }
  } //__input-text
  &__textarea{
    border-bottom: 1px solid $color-lilach;
    resize: none;
  } //__textarea
  &__submit{
    background-color: $color-green;
    color: #fff;
    font-weight: 300;
    font-size: 18px;
    padding: 8px;
  } //__submit
} //content-form

.acc{
  @at-root #{&}__content-title{
    display: block;
    float: right;
    text-align: left;
    font-size: 18px;
    font-weight: 900;
    color: #000;
    width: 12.5%;

    @include respond-to('medium'){
      width: 10%;
    } //---medium
  } //__title
  @at-root #{&}__content-text{
    display: block;
    float: right;
    font-size: 18px;
    font-weight: 300;
    color: #000;
    width: 56.25%;
    padding: 0 25px;

    @include respond-to('medium'){
      width: 60%;
    } //---medium
  } //__content-text
  @at-root #{&}__send{
    display: block;
    width: 31.25%;
    float: right;
    padding: 0 20px;

    @include respond-to('medium'){
      width: 30%;
    } //---medium

    span{
      font-size: 18px;
      font-weight: 900;
      display: block;
      margin-top: 20px;
      margin-bottom: 20px;
    } //span
  } //__send
  @at-root #{&}__btn{
    font-size: 20px;
    font-weight: 300;
    padding: 15px;
    width: 100%;
    margin-top: 15px;
    border-radius: 0;
    text-align: center !important;

    @include respond-to('medium'){
      font-size: 16px;
    } //---medium

    &:active{
      padding: 16px 14px 16px 14px;
    } //active
  } //__btn
  @at-root #{&}__open-btn{
    height: 60px;
    width: 60px;
    border-left: solid 1px #e1e1e1;
    position: relative;
    float: right;

    @include respond-to('small'){
      width: 30px;
      position: absolute;
      float: none;
    } //---small

    &:before{
      position: absolute;
      content:'';
      top: 50%;
      left: 45%;
      width: 10px;
      height: 10px;
      border: 2px solid #777;
      border-top-color: transparent;
      border-left-color: transparent;
      transform: rotate(45deg) translate(-50%, -50%);
      z-index: 1;
      display: block;
      transition: .1s;
    } //before
    &:after{
      width: 40px;
      height: 40px;
      position: absolute;
      content: '';
      background-color: #f4f4f4;
      border: 1px solid #e5e5e5;
      top: 10px;
      right: 10px;
      transition: .2s;

      @include respond-to('small'){
        width: 30px;
        height: 60px;
        top: 0;
        right: 0;
      } //---small
    } //after
  } //__open-btn
  @at-root #{&}__link{
    position: relative;

    &:not(.collapsed){
      .panel-title, .acc__location, .acc__number{
        color: #000;
      } //panel-title
      .acc__open-btn{
        &:after{
          background-color: $color-green;
        } //after
        &:before{
          transform: rotate(-135deg) translate(15%,-5%);
          border-bottom-color: #fff;
          border-right-color: #fff;
        } //before
      } //acc__open-btn
      .acc__location{
        display: block;
      }
      .acc__number{
        display: block;
      }
    } //not collapsed
  } //__link
  @at-root #{&}__location{
    width: 21%;
    font-size: 20px;
    color: #777;
    font-weight: 400;
    padding-top: 19px;
    float: right;
    padding-right: 15px;
    display: block;
    height: 60px;
    line-height: 1.1;
    border-left: 1px solid #e5e5e5;

    @include respond-to('medium'){
      font-size: 16px;
    } //---medium
    @include respond-to('small'){
      height: 30px;
      padding-top: 10px;
      width: 75%;
      margin-bottom: 10px;
      display: none;
    } //---small
  } //__location
  @at-root #{&}__number{
    font-size: 20px;
    color: #777;
    font-weight: 400;
    padding-top: 19px;
    float: right;
    padding-right: 15px;
    display: block;
    height: 60px;
    line-height: 1.1;

    @include respond-to('medium'){
      font-size: 16px;
    } //---medium
    @include respond-to('small'){
      height: 30px;
      padding-top: 10px;
      width: 25%;
      display: none;
    } //---small
  } //__number
} //acc

.panel-group{
  .panel{
    border-radius: 0;
    border: none;
    border-top: 1px solid #cacaca;

    &+.panel{
      margin-top: 0;
    }

    &:nth-of-type(even){
      .panel-heading{
        background-color: #f4f4f4;
      }
    } //even

    .panel-body{
      background-color: #f2f2f2;
      box-shadow: 0 0 20px rgba(0,0,0,.25) inset;
    } //panel-body
  } //panel

  .panel-heading{
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0,0,0,.25);

    a{
      display: block;
      width: 100%;
      height: 60px;
      padding: 0px;
    } //a
    //z-index: 1;
    //position: relative;

    &+.panel-collapse{
      &>.panel-body{
        border-top: none;
      }
    }
  }
} //panel-group

.panel-title{
  font-size: 20px;
  color: #777;
  font-weight: 400;
  padding-top: 19px;
  float: right;
  padding-#{$right}: 15px;
  display: block;
  height: 60px;
  width: calc(70% - 60px);
  border-left: 1px solid #e5e5e5;

  @include respond-to('medium'){
    font-size: 16px;
  } //---medium
  @include respond-to('small'){
    padding-top: 10px;
    padding-#{$right}: 40px;
    border: none;
    width: 100%;
  } //---small
} //panel-title

.panel-heading{
  padding: 0;
} //panel-heading

.content-small-full{
  @include respond-to('small'){
    width: 100%;
    float: #{$right};
    text-align: #{$right};
    padding-right: 15px;
    padding-left: 15px;
  }
}

.prj-nav{
  padding-#{$right}: 40px;

  @include respond-to('medium'){
    padding-#{$right}: 55px;
    margin-top: 150px;
  }

  span{
    padding: 0 15px;
  }
  &__prv, &__nxt{
    position: relative;

    &:before, &:after{
      content: '';
      position: absolute;
    }
    &:before{
      height: 1px;
      width: 20px;
      background-color: #000;
      top: 12px;
    } //before
    &:after{
      top: 7px;
      height: 10px;
      width: 10px;
      border: 1px solid transparent;
      border-right-color: #000;
      border-top-color: #000;
    } //after
  } //__prv, __nxt
  &__prv{
    &:before{
      #{$right}: -40px;
    } //before
    &:after{
      #{$right}: -40px;
      transform: rotate(45deg);
    } //after
  } //__prv
  &__nxt{
    &:before{
      #{$left}: -40px;
    } //before
    &:after{
      #{$left}: -40px;
      transform: rotate(-135deg);
    } //after
  } //__nxt
} //prj-nav

.prj-titles{
  margin-bottom: 100px;
  padding-#{$right}: 100px;
  padding-top: 20px;

  @include respond-to('medium'){
    margin-bottom: 20px;
    padding-#{$right}: 15px;
    padding-top: 0px;
  }

  &__summary{
    @include respond-to('medium'){
      font-size: 16px;
    }
  }
} //prj-titles

.prj-banner{
  padding-#{$left}: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 100px;
  background: linear-gradient(to right, $color-orange 33%, transparent 33%);

  &__img{
    width: 100%;
    height: 700px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
} //prj-banner

.prj-content{
  text-align: center;
  margin-bottom: 40px;

  @include respond-to('medium'){
    padding-left: 15px;
    padding-right: 15px;
  }

  h2, h3{
    letter-spacing: -.02em;
    font-weight: 600;
  } //h2, h3
  h3{
    font-size: 40px;

    @include respond-to('medium'){
      font-size: 25px
    }
  } //h3
  h2{
    font-size: 66px;
    line-height: 81px;

    @include respond-to('medium'){
      font-size: 35px;
      line-height: 40px;
    }
  } //h2
  p{
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -.02em;
    color: #595959;
    margin-bottom: 60px;

    @include respond-to('medium'){
      font-size: 16px;
      line-height: 22px;
    }
  } //p
} //prj-content

.prj-img{

  img{
    opacity: 0;
  }
  &__img{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;
  } //__img
} //prj-img

.prj-lightgallery{
  &__first{
    position: relative;

    &:after{
      position: absolute;
      content: '';
      z-index: 1;
      width: 390px;
      height: 470px;
      background-color: $color-orange;
      top: -35px;
      #{$right}: -20px;

      @include respond-to('small'){
        width: 40%;
        height: 355px;
      }
    } //__decoration
  } //__first
} //prj-lightgallery

.article-main{
  .content-main__title{
    padding-bottom: 20px;
    margin-bottom: 50px;
    position: relative;

    &:after{
      content:'';
      width: 150px;
      height: 5px;
      background-color: $color-orange;
      #{$right}: 0;
      bottom: 0;
      position: absolute;
    }
  }
  .content-main__text{
    p{
      margin-bottom: 30px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
