.fac-head{
  @include respond-to('huge'){
    padding-left: 15px;
    padding-right: 15px;
  }
  &__title{
    font-size: 30px;
    color: $color-light-blue;
    font-weight: 800;
    margin: 0 0 40px;
    padding: 0 0 40px;
    border-bottom: 1px solid #e6e6e6;

    @include respond-to('small'){
      font-size: 25px;
    } //---small
  } //__title
  &__text{
    P{
      font-size: 18px;
      color: #000;
      font-weight: 300;
      @include respond-to('small'){
        font-size: 16px;
      } //---small
      @include respond-to('extra-small'){
        font-size: 14px;
      } //---small
    } //P
  } //__text
} //fac-head

.fac-block{
  box-shadow: 0 0 20px rgba(0,0,0,.08);
  margin-bottom: 30px;
  position: relative;

  &:hover{
    .img-effect_zoom{
      &>img{
        min-width: 103%;
        min-height: 103%;
        left: 8px;
        bottom: 8px;
      }
    }
  }

  &__titles{
    color: #fff;
    position: absolute;
    top: 0;
    #{$right}: 0;
    transform: translateY(-100%);
    padding: 15px;
    h2{
      font-size: 30px;
      font-weight: 800;

      @include respond-to('small'){
        font-size: 25px;
      }
    } //h2
    span{
      font-size: 16px;
      font-weight: 300;
      @include respond-to('small'){
        font-size: 14px;
      }
    } //span
  } //__titles
  &__details{
    span, a{
      font-size: 16px;
      color: #000;
      line-height: 24px;
      font-weight: 300;
      @include respond-to('extra-small'){
        font-size: 10px;
        line-height: 10px;
      }
    } //span
    a{
      color: $color-light-blue;
      text-decoration: underline;
    } //a
    img{
      margin-#{$left}: 10px;
    }
  } //__details
  &__img-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, #000, transparent);
    opacity: .7;
  }
  &__img{
    position: relative;
  }
  &__info{
    padding: 15px 30px;
    position: relative;
  }
  &__logo{
    //padding-#{$right}: 30px;
    text-align: left;
  } //__logo
} //fac-block
