.similars{
  &__title{
    color: $color-lilach;
    font-size: 26px;
    font-weight: 300;
    border-top: 1px solid #e6e6e6;
    padding: 25px 0;
  }
}

.item-left{
  &__image{
    width: 100%;
    height: 500px;
    border: 1px solid #e6e6e6;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include respond-to('small'){
      height: 300px;
    } //---small
  } //__image
  &__price-block{
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e6e6e6;

    @include respond-to('medium'){
      padding-left: 15px;
      padding-right: 15px;
    }
  } //__price-block
  &__price-text{
    font-size: 16px;
    font-weight: 300;
    color: #000;
  } //__price-text
  &__price{
    color: $color-lilach;
    font-weight: 800;
    font-size: 40px;
    display: block;
    line-height: 40px;

    @include respond-to('small'){
      font-size: 20px;
    }
    @include respond-to('extra-small'){
      display: inline-block;
      margin-#{$right}: 20px;
    }
  } //__price
  &__btn{
    padding: 5px 20px;
    color: #fff;
    font-weight: 900;
    font-size: 30px;
    background-color: $color-green;
    box-shadow: none;
    transition: .3s;
    filter: none;
    position: relative;
    display: inline-block;
    margin-top: 10px;

    @include respond-to('small'){
      font-size: 20px;
    }

    &:hover{
      color: #fff;
      box-shadow: 2px 2px 10px rgba(0,0,0,.15);
      filter: brightness(100%);
    } //hover

    &:focus{
      color: #fff;
    }

    &:active{
      box-shadow: 0 0 3px rgba(0,0,0,0.5);
      top: 1px;
      left: 1px;
      color: #fff;
    } //active
  } //__btn

  &__secure{
    display: inline-block;
    margin-top: 15px;
    margin-#{$right}: 40px;
  } //__secure
} //item-left

.item-info{
  padding-#{$left}: 20px;

  @include respond-to('medium'){
    padding-left: 15px;
    padding-right: 15px;
  }
  p, h2, h3{
    margin-bottom: 25px;
    font-weight: 300;
    text-align: justify;
  }
  p{
    font-size: 18px;

    @include respond-to('small'){
      font-size: 16px;
    }
  } //p
  h2{
    font-size: 26px;
    color: $color-lilach;

    @include respond-to('small'){
      font-size: 20px;
    }
  } //h2
  h3{
    font-size: 26px;
    color: #000;

    @include respond-to('small'){
      font-size: 20px;
    }
  } //h3

  ul{
    margin-bottom: 25px;
    li{
      position: relative;
      padding-#{$right}: 35px;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 300;
      &:before{
        content: url(/assets/images/bullet.png);
        position: absolute;
        #{$right}: 20px;
        top: -3px;
      } //before
    } //li
  } //ul
} //item-info

.item-content{
  font-size: $item-content-size;
  color: $item-content-color;
  padding: $item-content-padding-v $item-content-padding-h;

  p{
    margin-bottom: $item-content-para-space;
  }
}
