//general layout
//colors
$color-black: #000;
$color-white: #fff;
$color-dark-grey: #323232;
$color-grey: #5f5f5f;
$color-silver: #f0f0f0;
$color-steel-grey: #777;
$color-light-grey: #c7c7c7;

$color-blood-red: #a41010;
$color-red: #e44957;
$color-orange: #f0521a;
$color-yellow: #ffcd64;

$color-light-blue: #006488;
$color-water-blue: #dee1e8;
$color-aqua: #44abe8;
$color-deep-blue: #314276; //#133c59
$color-navy-blue: #00202e;
$color-turquise: #65dbc3;
$color-violet: #a62a57;

$color-green: #afd46d;
$color-light-green: #13995c;
$color-lime: #96ce4d;
$color-peach: #f5e5da;
$color-pink: #f2d8d8;
$color-fuxia: #d2166a;
$color-lilach: #6d76b6;

$color-shadow-black: rgba(0,0,0,.15);
$color-shadow-black-opac: rgba(0,0,0,0.5);


//header variables
$general-header-height: 150px;

$header-secondary-color: #1362b0;
$header-sec-separator-margin: 0 8px;
$header-sec-spacing-r: 75px;

$header-font-size-medium: 18px;
$header-font-size-small: 14px;
$header-font-size-big: 20px;
$header-font-color: #000;
$header-font-weight: 300;

$header-menu-spaces: 35px;
$header-menu-space-reduced: 10px;
$header-menu-spacing-top: 20px;
$header-menu-items-v-padding: 5px;
$Header-menu-hover-color: $color-lilach;
$header-menu-hover-bg: $color-green;

$header-selected-border-type: solid;
$header-selected-border-size: 0;
$header-selected-border-color: $color-blood-red;

$header-sub-padding-v: 20px;
$header-sub-padding-h: 20px;
$header-sub-distance: 30px;
$header-sub-width: 790px;
$header-sub-line-height: 26px;
$header-sub-offset-h: 0px;
$header-sub-shadow-v-h-s: 0px 10px 15px;
$header-sub-column-width: 180px;

$header-menu-shadow-x: 0;
$header-menu-shadow-y: 0;
$header-menu-shadow-size: 35px;
$header-menu-shadow-color: $color-aqua;

$header-menu-spacing-top-scrolling: 0px;

$header-shadow-x: 0;
$header-shadow-y: 0;
$header-shadow-size: 25px;
$header-shadow-spread: 0;
$header-shadow-color: $color-shadow-black;

//footer variables
$footer-padding: 55px;
$footer-min-height: 440px;
$footer-bg-color: $color-black;

$footer-title-color: $color-light-grey;
$footer-title-size: 18px;
$footer-title-weight: 700;

$footer-link-color: $color-steel-grey;
$footer-link-size: 16px;
$footer-link-height: 30px;
$footer-links-column-space: 110px;
$footer-links-column-right: 75px;

$footer-adress-color: $color-white;
$footer-address-size: 16px;

$social-size: 20px;
$social-color: $color-steel-grey;
$social-spacing: 5px;
